import React from "react";
import { useParams } from "react-router-dom";
import { message } from "antd";

import { compose } from "../../core";

import AdminStoryDetailView from "../components/AdminStoryDetailView";

import { withAllCommentsForStoryByStoryId, withEditStory, withStoryById } from "./StoryOperations";
import { subscribeToPublishedStory } from "./StorySubscriptions";
import { getIntFromString } from "../../look";

const AdminStoryDetail = props => {
  const { editStory, subscribeToMore, storyById } = props;
  const { id } = useParams();
  const [actionLoading, setActionLoading] = React.useState(false);
  const storyDetails = [
    { id:5,
      publishedStoryById: {
        id: "U3RvcnlUeXBlOjU=",
        author: "Vidula Prasad, vidula@brain-ayan.com",
        banner:
          "https://res.cloudinary.com/brain-ayan/image/upload/v1715172132/chovptvyxjv9l6r85xn5.png",
        title: "The Balancing Act",
        content: `<p>I found that my life resembled the city that I lived in so accurately. Mumbai, the city of dreams, a city of chaos where you need to find your calm. The clock of my life demanded more hours every day. My role as a procurement specialist at Atlas Corp Co. was both invisible and essential-_ensuring every asset was accounted for, every transaction seamless.</p>
    
    <p>The clock struck past midnight, and my heart sank. I imagined my year old twins, sleeping peacefully in their nursery back home - safe &amp; sound, happily unaware of the madness of this world. My heart wanted to race back home. It heart further sank just at the thought of going home late, again. A hint of sadness gripped me, as I pictured the disappointed faces of my &#39;culturally rich&#39; &amp; so called &#39; modern&#39; in- laws. Still, the mother in me whispered reassurances.</p>
    
    <p>&quot;This sacrifice is for their future.&quot; A universal struggle that resonates with mothers everywhere. Working at Atlas Corp Co. presented a monumental challenge. The data that I was handed over resembled a chaotic jigsaw puzzle with missing pieces. I tracked assets, chased leads, and pieced together information that seemed determined to remain hidden. It was a race against time, against expectations, against my own limits.</p>
    
    <p>My dedication meant late nights, a couple of escalations, and a precarious balance. Professional reputation clashed with familial duty. I worked long hours, giving more of myself than I thought possible. Sometimes, it felt like the world conspired against me. To stir the storm further, I was dealing with office politics coupled with an escalation. The world sometimes felt a hopeless place to live in. Yet, I stood my ground. I ensured that all my office clashes were resolved one at a time. I explained my reasons patiently to the stakeholders. Office politics was sorted gradually, I negotiated, explained, and sorted out disagreements. Slowly, the storm subsided.</p>
    
    <p>I was filled with uncertainty and anxiety as the project&#39;s deadline approached. Could I meet the deadline? Would my efforts be recognized? Would my family understand? Much to everyone&#39;s surprise I was able to complete the work on time. I won! feeling happy and stress-free. I proved my dedication towards my work, my love towards my family.</p>
    
    <p>Looking back, I saw growth amid insecurities. This journey was a testament to resilience. But as the sun painted Mumbai&#39;s skyline, I knew it wasn&#39;t the end. Another chapter awaited a canvas for new challenges, fresh balances, and future victories. And I was ready.</p>
    `,
        storyTags: {
          pageInfo: {
            hasNextPage: false,
            hasPreviousPage: false,
            startCursor: null,
            endCursor: null,
            __typename: "PageInfo",
          },
          edges: [],
          totalCount: 0,
          edgeCount: 0,
          __typename: "TagTypeConnection",
        },
        published: true,
        datecreated: "2024-05-08T12:42:16.710478+00:00",
        likes: 1,
        thumbnailImage:
          "https://res.cloudinary.com/brain-ayan/image/upload/v1715172124/wipsdaj0pgeqgnff9kla.png",
        commentSet: {
          totalCount: 0,
          __typename: "CommentTypeConnection",
        },
        __typename: "StoryType",
      },
    },
  ];

  React.useEffect(() => {
    const subscribe = subscribeToPublishedStory(subscribeToMore /* , props.filter */);
    return () => subscribe();
  });
  const selectedStory = storyDetails?.find(item => item.id === Number(id))

  const handleEdit = async (e, id) => {
    setActionLoading(true);
    try {
      await editStory({
        id: Number(getIntFromString(id)),
        published: e
      });
      setActionLoading(false);
    } catch (e) {
      setActionLoading(false);
      throw new Error(e.message);
    }
  };

  return <AdminStoryDetailView publishedStoryById={selectedStory?.publishedStoryById} selectedStoryId={id}  {...props} />;
};

export default AdminStoryDetail;
