import React from 'react';

import { compose } from '../../core';
// import allStories from './data.js' 

import AllStoriesView from '../components/AllStoriesView';

import { withAllPublishedStories, withStoryState, withStoryFilterUpdating } from './StoryOperations';
import { subscribeToAllPublishedStory } from './StorySubscriptions';

const AllStories = props => {
  // console.log('allStories data:', allStories)
  const { subscribeToMore } = props;
  React.useEffect(() => {
    const subscribe = subscribeToAllPublishedStory(subscribeToMore /* , props.filter */);
    return () => subscribe();
  }, []); // Added dependency array

  // return <AllStoriesView allPublishedStories={allStories.data.allPublishedStories} {...props} />;
  return <AllStoriesView {...props} />;

};

export default AllStories;
