import React from 'react';
import { useParams } from 'react-router-dom';
import { message } from 'antd';

import { compose } from '../../core';

import StoryDetailView from '../components/AdminStoryDetailView';

import { withLikeStory, withPublishedStoryById } from './StoryOperations';
import { subscribeToPublishedStory } from './StorySubscriptions';

const StoryDetail = props => {
  const storyDetails = [
    {
      id: 5,
      publishedStoryById: {
        id: "U3RvcnlUeXBlOjU=",
        author: "Vidula Prasad, vidula@brain-ayan.com",
        banner:
          "https://res.cloudinary.com/brain-ayan/image/upload/v1715172132/chovptvyxjv9l6r85xn5.png",
        title: "The Balancing Act",
        content: `<p>I found that my life resembled the city that I lived in so accurately. Mumbai, the city of dreams, a city of chaos where you need to find your calm. The clock of my life demanded more hours every day. My role as a procurement specialist at Atlas Corp Co. was both invisible and essential-_ensuring every asset was accounted for, every transaction seamless.</p>
    
    <p>The clock struck past midnight, and my heart sank. I imagined my year old twins, sleeping peacefully in their nursery back home - safe &amp; sound, happily unaware of the madness of this world. My heart wanted to race back home. It heart further sank just at the thought of going home late, again. A hint of sadness gripped me, as I pictured the disappointed faces of my &#39;culturally rich&#39; &amp; so called &#39; modern&#39; in- laws. Still, the mother in me whispered reassurances.</p>
    
    <p>&quot;This sacrifice is for their future.&quot; A universal struggle that resonates with mothers everywhere. Working at Atlas Corp Co. presented a monumental challenge. The data that I was handed over resembled a chaotic jigsaw puzzle with missing pieces. I tracked assets, chased leads, and pieced together information that seemed determined to remain hidden. It was a race against time, against expectations, against my own limits.</p>
    
    <p>My dedication meant late nights, a couple of escalations, and a precarious balance. Professional reputation clashed with familial duty. I worked long hours, giving more of myself than I thought possible. Sometimes, it felt like the world conspired against me. To stir the storm further, I was dealing with office politics coupled with an escalation. The world sometimes felt a hopeless place to live in. Yet, I stood my ground. I ensured that all my office clashes were resolved one at a time. I explained my reasons patiently to the stakeholders. Office politics was sorted gradually, I negotiated, explained, and sorted out disagreements. Slowly, the storm subsided.</p>
    
    <p>I was filled with uncertainty and anxiety as the project&#39;s deadline approached. Could I meet the deadline? Would my efforts be recognized? Would my family understand? Much to everyone&#39;s surprise I was able to complete the work on time. I won! feeling happy and stress-free. I proved my dedication towards my work, my love towards my family.</p>
    
    <p>Looking back, I saw growth amid insecurities. This journey was a testament to resilience. But as the sun painted Mumbai&#39;s skyline, I knew it wasn&#39;t the end. Another chapter awaited a canvas for new challenges, fresh balances, and future victories. And I was ready.</p>
    `,
        storyTags: {
          pageInfo: {
            hasNextPage: false,
            hasPreviousPage: false,
            startCursor: null,
            endCursor: null,
            __typename: "PageInfo",
          },
          edges: [],
          totalCount: 0,
          edgeCount: 0,
          __typename: "TagTypeConnection",
        },
        published: true,
        datecreated: "2024-05-08T12:42:16.710478+00:00",
        likes: 1,
        thumbnailImage:
          "https://res.cloudinary.com/brain-ayan/image/upload/v1715172124/wipsdaj0pgeqgnff9kla.png",
        commentSet: {
          totalCount: 0,
          __typename: "CommentTypeConnection",
        },
        __typename: "StoryType",
      },
    },
    {
      id: 4,
      publishedStoryById: {
        id: "U3RvcnlUeXBlOjQ=",
        author: "Vidula Prasad, vidula@brain-ayan.com",
        banner:
          "https://res.cloudinary.com/brain-ayan/image/upload/v1715171885/u0dvpcopyf5irmxxrmrf.png",
        title: "Two Weeks of Turmoil",
        content: `<meta charset="UTF-8"><meta name="viewport" content="width=device-width, initial-scale=1.0">
  <style type="text/css">body {
              font-family: Arial, sans-serif;
              line-height: 1.6;
              margin: 0;
              padding: 20px;
          }
          h1 {
              text-align: center;
              margin-bottom: 20px;
          }
          p {
              text-align: justify;
              margin-bottom: 20px;
          }
          .highlight {
              font-weight: bold;
          }</style>
  <h1>TWO WEEKS OF TURMOIL</h1>
  
  <p>Source Attribution:</p>
  
  <p style="{{margin-bottom:&quot;10px&quot;}}">The stories presented herein are the result of interviews conducted exclusively by Brainayan. We extend our deepest appreciation to all who allowed us a glimpse into their experiences.Your courage and resilience have breathed life into these narratives, guiding us along the path of transformation and growth.</p>
  
  <p>Stepping into a managerial role in the medical transcription industry at 32 was a significant leap for me, armed with a blend of academic knowledge and professional certifications. I was just beginning to master the art of leadership when an unexpected challenge tested my mettle.</p>
  
  <p>The week commenced routinely, with plans laid out and a familiar buzz of activity. Yet, a subtle tension lingered in the air, fuelled by rumours of a swine flu outbreak. Despite the whispers, the normalcy of the office environment remained undisturbed until that Monday morning.</p>
  
  <p>As I entered the office, coffee cup in hand, a disquieting silence greeted me. The once lively workspace was eerily deserted. Confusion set in as I pondered the whereabouts of my team.</p>
  
  <p class="highlight">Completely unaware, over the weekend, a decision had been made allowing half of my team that works on-field to work remotely due to the flu scare-a decision that had not been communicated to me. It felt like a trust fall gone wrong. The thought of keeping me informed about my colleagues did not even cross their minds.</p>
  
  <p>Faced with mounting STAT files and precarious workflows, the management&#39;s reassurances seemed hollow. Yet, I refused to succumb to the chaos. With guidance from a composed quality manager and strategic discussions with reporting managers, we began to navigate the crisis.</p>
  
  <p>We focused on optimization, extracting maximum productivity from our limited resources, and streamlining processes. The in-office team became my anchor during this tumultuous period. Together, we found our rhythm and preserved the team&#39;s morale.</p>
  
  <p>I started spending some time alone, away from the madness every day. Those 15 minutes of solitude became my haven, a time to recharge and refocus.</p>
  
  <p class="highlight">The past two weeks really put us through a roller-coaster ride. But we became tougher and more united than before. We learned that staying connected, pulling together, and thinking on our feet can make all the difference when the going gets tough. When the global head gave us a shout-out, it wasn&#39;t just a pat on the back for a job well done; it was a nod to our team&#39;s grit and our ability to roll with the punches.</p>`,
        storyTags: {
          pageInfo: {
            hasNextPage: false,
            hasPreviousPage: false,
            startCursor: null,
            endCursor: null,
            __typename: "PageInfo",
          },
          edges: [],
          totalCount: 0,
          edgeCount: 0,
          __typename: "TagTypeConnection",
        },
        published: true,
        datecreated: "2024-05-08T12:38:11.755083+00:00",
        likes: 1,
        thumbnailImage:
          "https://res.cloudinary.com/brain-ayan/image/upload/v1715171879/xjcrmrbmhxosc8yvhctz.png",
        commentSet: {
          totalCount: 0,
          __typename: "CommentTypeConnection",
        },
        __typename: "StoryType",
      },
    },
    {
      id: 3,
      publishedStoryById: {
        id: "U3RvcnlUeXBlOjM=",
        author: "Steffanie Grimes, steffanie@brain-ayan.com",
        banner:
          "https://res.cloudinary.com/brain-ayan/image/upload/v1667843409/mtcqjveod03mrjxyawrf.jpg",
        title: "Dealing With Rejections",
        content: `<p paraeid="{5db809ee-0f4b-4357-a73c-e88c35de56e0}{4}" paraid="1916973607">I am Steffanie Grimes, 26 years old, a mental health professional and this is my story.&nbsp;&nbsp;</p>
  
  <p paraeid="{5db809ee-0f4b-4357-a73c-e88c35de56e0}{16}" paraid="157522890">Life is what happens to you while you&rsquo;re busy making other plans. Something of that sort happened with me as well. I had just completed my post-graduation and soon got a job as a college counsellor in a renowned Law College. In the beginning everything was well, I was given complete freedom to work, the way I want to work, the cases I want to focus on, I was taking different awareness sessions for students and professors, handling individual and group cases and so much more. Then things took a turn for the worse. As soon as the college was done with the NAAC assessment, the need for a counsellor was over. Slowly the management turned into people I couldn&rsquo;t recognise, and I was pushed into the admin department and was compelled to do tele-calling work for them, which had no relevance to me or my work.&nbsp; Finally, the inevitable happened and I was forced to resign from my position.&nbsp;</p>
  
  <p paraeid="{5db809ee-0f4b-4357-a73c-e88c35de56e0}{38}" paraid="1460346618">I still didn&rsquo;t lose hope, though to be honest I became a bit desperate for a job. When I returned to my hometown, it was already the end of the year. I kept getting rejected from all the places I applied. My dreams were starting to crumble, my hopes to be able to build a good career were starting to fade. I remember clearly when I was at my lowest, my father held my hand, looked at me and said, &ldquo;I believe in you.&rdquo; When I knew my biggest supporter was cheering for me, after that, nothing could stop me.&nbsp;</p>
  
  <p paraeid="{5db809ee-0f4b-4357-a73c-e88c35de56e0}{52}" paraid="694678339">Finally, I got a job as a school counsellor in an NGO, but unlike a conventional school counsellor, it was more than just sitting in a closed cabin and doing counselling. I was catering to the needs of children belonging to the rural communities.&nbsp; But by the time I could wrap my head around my actual role, the pandemic hit India. All schools were shut down, educators started digital learnings for students. A lot of families of our students lost their jobs, some students lost their family members, while they were struggling to meet their daily needs.&nbsp;I didn&rsquo;t know how to handle such situations, how to support them. I was persistent to learn though. I kept trying to learn and grasp whatever I could, I kept asking questions to my manager. I truly wanted to help those families at least in whatever emotional limits that I could. I quickly got adapted to the online system and created a listening space for parents, teachers who were going through a tough time.&nbsp;</p>
  
  <p paraeid="{5db809ee-0f4b-4357-a73c-e88c35de56e0}{94}" paraid="126693617">I thought I was doing well in my work; I was proud of myself. After some time, my company&rsquo;s policies changed and being a frontline worker, I was forced to travel to work regularly. We had to go inside the community to spread awareness.&nbsp;Even after sharing my concern of not being comfortable with such a protocol, my cry went unheard and because of that, my image was tarnished in the school only because I was trying to put my family&rsquo;s safety first. I was being judged for everything after that and put down for everything from my appearance to my approach towards my work. I was questioned infront of groups of people for the level of my assessments only because I denied labelling a child with any tag whatsoever.&nbsp;</p>
  
  <p paraeid="{5db809ee-0f4b-4357-a73c-e88c35de56e0}{114}" paraid="1161712418">I guess you understand who your true well-wishers are when you are going through the toughest phase. Some of my colleagues were truly supportive and stood by my side. My biggest strength were my students itself; I have learned so much from them. Their power and courage encouraged me to strive harder. Looking at them made me more focused on my real goal, towards their and my future. With time, I became more self-aware and clearer with my vision and focus on strengthening my abilities and interests.&nbsp;&nbsp;</p>
  
  <p paraeid="{5db809ee-0f4b-4357-a73c-e88c35de56e0}{126}" paraid="567177597">Today I can look back and smile, just remembering the smiles of those little ones who made a difference in my life. As I know now that those experiences are a small part of my story, a small part of life but they do not define who I am. Honestly, what keeps me going through this journey is my strong support system of my dad, supportive colleagues and my dear students whom I will always cherish.&nbsp;</p>
  `,
        storyTags: {
          pageInfo: {
            hasNextPage: false,
            hasPreviousPage: false,
            startCursor: null,
            endCursor: null,
            __typename: "PageInfo",
          },
          edges: [],
          totalCount: 0,
          edgeCount: 0,
          __typename: "TagTypeConnection",
        },
        published: true,
        datecreated: "2022-11-07T19:15:10.572619+00:00",
        likes: 0,
        thumbnailImage:
          "https://res.cloudinary.com/brain-ayan/image/upload/v1667843447/exxwrbkmtjnksuaxlgrz.png",
        commentSet: {
          totalCount: 0,
          __typename: "CommentTypeConnection",
        },
        __typename: "StoryType",
      },
    },
    {
      id:2,
      publishedStoryById: {
        id: "U3RvcnlUeXBlOjI=",
        author: "Anonymous",
        banner: "https://res.cloudinary.com/brain-ayan/image/upload/v1667842792/eegbqdxuijgcfkv7avtu.png",
        title: "Dealing With The Downward Spiral",
        content: `
          <p paraeid="{c00bb5e5-46de-49a8-ae1b-5b6c2ee4f1bb}{238}" paraid="1767410622">
            Anonymous, 24, Male currently a student pursuing my Master&rsquo;s in Industrial Psychology.&nbsp;&nbsp;
          </p>
          <p paraeid="{c00bb5e5-46de-49a8-ae1b-5b6c2ee4f1bb}{246}" paraid="967307267">
            I had hit rock bottom. I was burnt out and couldn&rsquo;t find the will to get out of my bed in the mornings. It felt like there&rsquo;s a volcano burning inside me all the time. I had joined a new position &hellip;. months ago, as the HR Consultant at a known MNC in the IT industry. I was new to Human Resources. In the very first month, I was handed 30 positions to fill and schedule 100 interviews. There was no support. Every time I asked for help or support from any of my team members or the higher management, but I found no one by my side.&nbsp; Nobody I could turn to for guidance. I was working with 2 hiring managers from different teams. Two weeks into my new role, I was threatened to be fired by the team leads. I was feeling lost and extremely stressed out. I felt like crying most of the time.&nbsp;
          </p>
          <p paraeid="{f079ebcf-4a6f-4064-94e0-568d66b27bd1}{71}" paraid="1742982736">
            There was this team I wanted to work with. The manager was on leave when I joined, so when she came back, I contacted her.&nbsp;She understood what I was going through, talked with the HR head and I moved to her team. My workload became more manageable. But by then, my downward spiral had already started. I was demotivated even though I pushed myself to continue. I found myself struggling to start the day. I was experiencing severe burnout. It felt like the end to me.&nbsp;
          </p>
          <p paraeid="{f079ebcf-4a6f-4064-94e0-568d66b27bd1}{115}" paraid="290719251">
            This is when I reached out to my mentor. He/she helped me connect with a mental health professional. I was diagnosed with clinical depression. With the support of my mentor, friends and counsellor, I got back on my feet. My friends were especially helpful in keeping me optimistic about the future and giving me the strength and courage to move on. I resigned from the company and started looking for alternatives. A word of caution: It may not be the best decision to resign before you have other options in hand.)&nbsp;
          </p>
          <p paraeid="{f079ebcf-4a6f-4064-94e0-568d66b27bd1}{159}" paraid="1479345003">
            Through this harrowing experience, I learned the importance of my friends and my mentor, the people who stood by me and helped me get up and move on. I realized it is important and okay to reach out for help when I found myself struggling. I also started making time for myself. I would sit in silence listening to my thoughts. This gave me clarity on what I needed. Avoid being around toxic people and situations. As a first step, be aware that they are toxic. You can then take steps to protect yourself. If your workplace is toxic, look for alternatives immediately.&nbsp;&nbsp;
          </p>
        `,
        storyTags: {
          pageInfo: {
            hasNextPage: false,
            hasPreviousPage: false,
            startCursor: null,
            endCursor: null,
            __typename: "PageInfo"
          },
          edges: [],
          totalCount: 0,
          edgeCount: 0,
          __typename: "TagTypeConnection"
        },
        published: true,
        datecreated: "2022-11-07T19:08:04.035243+00:00",
        likes: 0,
        thumbnailImage: "https://res.cloudinary.com/brain-ayan/image/upload/v1667843199/ceqs8lpb9ohgnxqummbq.png",
        commentSet: {
          totalCount: 0,
          __typename: "CommentTypeConnection"
        },
        __typename: "StoryType"
      }
    }
  ];
  const { likeStory, subscribeToMore } = props;
  const { id } = useParams();
  const selectedStory = storyDetails?.find(item => item.id === Number(id))

  console.log('this is the id bjbj7which i was searching ==== ', id,selectedStory)

  React.useEffect(() => {
    const subscribe = subscribeToPublishedStory(subscribeToMore /* , props.filter */);
    return () => subscribe();
  });

  const handleLike = async () => {
    try {
      const response = await likeStory(id);
      response && message.success('Story liked!');
      return response;
    } catch (e) {
      throw Error(e.message);
    }
  };


  return <StoryDetailView publishedStoryById={selectedStory?.publishedStoryById} selectedStoryId={id} onLike={handleLike} {...props} />;
};

export default StoryDetail;
