import React, { useState, useEffect } from 'react';
import { Row, Col, Spin, Divider } from 'antd';
import { getIntFromString, MetaTags, ReactMarkdown } from '../../look';
import AddToolToRecent from './AddToolToRecent';
import { RenderFormSection } from './generic-tool';

const GenericToolView = props => {
  const { loading, getToolById, admin = false, refetch } = props;
  const [items, setItems] = useState([]);

  useEffect(() => {
    if (!loading)
      setItems([
        ...(getToolById?.textsection?.edges?.map(({ node }) => ({ ...node, type: 'textsection' })) || []),
        ...(getToolById?.formsection?.edges?.map(({ node }) => ({ ...node, type: 'formsection' })) || [])
      ]);
  }, [loading]);

  const RenderTextSection = ({ textSection, idx }) => {
    return (
      <Row gutter={24}>
        <Col span={24}>
          {/* Dont't show divider in back to back textsections */}
          {items[idx - 1]?.type !== 'textsection' && <Divider />}

          {/* <ReactMarkdown>{textSection.text}</ReactMarkdown> */}
          <ReactMarkdown>{textSection.renderedText || '<p></p>'}</ReactMarkdown>

          {/* Dont't show divider in back to back textsections */}
          {items[idx + 1]?.type !== 'textsection' && <Divider />}
        </Col>
      </Row>
    );
  };

  return (
    <Row justify="center">
      <AddToolToRecent />
      <MetaTags title="Tool Details" description={`This is tool details page`} />

      <Col
        span={20}
        style={{
          border: '1px solid #EBEBEB',
          borderRadius: 10,
          padding: '50px 0px',
          paddingBottom: 80,
          backgroundColor: '#fff'
        }}
      >
        <Spin spinning={loading} size="large">
          <div style={{ minHeight: '70vh' }}>
            {!loading && (
              <Row style={{ marginTop: '10px' }} justify="center">
                {items
                  .sort((a, b) => (a.sequence < b.sequence ? -1 : a.sequence > b.sequence ? 1 : 0))
                  .map((section, idx) =>
                    section.type === 'textsection' ? (
                      <Col span={20} align="left">
                        <RenderTextSection textSection={section} idx={idx} />
                      </Col>
                    ) : (
                      <Col span={24} style={{ backgroundColor: (2 * idx + 1) % 2 !== 0 && '#8080801c', paddingTop: '10px' }}>
                        <Row justify="center">
                          <Col span={20} align="left">
                            <RenderFormSection
                              admin={admin}
                              formSectionId={getIntFromString(section.id)}
                              formSection={section}
                              refetch={refetch}
                            />
                          </Col>
                        </Row>
                      </Col>
                    )
                  )}
              </Row>
            )}
          </div>
        </Spin>
      </Col>
    </Row>
  );
};

export default GenericToolView;
